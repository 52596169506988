/* eslint react-hooks/exhaustive-deps: 0 */
/* eslint eqeqeq: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { convertCurrencyNumber } from "../../../../../utils/helpers/NumberHelper";
import { withRouter } from "react-router-dom";
import Button from "../../../../common/button/Button";
import {
  getDateFormatedFromString,
  getDateFormatedFromStringWithTime,
} from "../../../../../utils/helpers/DateTimeHelper";
import {
  actionModal,
  loadPedidoCompra,
} from "../../../../../actions/aprovacao/AprovacaoAction";
import { downloadPedidoDocument } from "../../../../../actions/pedidos/PedidosAction";
import { formatCNPJ } from "../../../../../utils/helpers/StringHelper";
import history from "../../../../common/routers/history";
import {
  APROVACAO_ROUTE_PATH,
  LISTA_ROUTE_PATH,
} from "../../../../common/routers/constantesRoutes";
import { APROVACAO, READ } from "../../../../../utils/actionsAndFeaturesAuth";
import { authorizationFunction } from "../../../../../actions/authorization/AuthorizationAction";

let ModalPedidoCompra = (props) => {
  const {
    actionModal,
    open,
    pedido,
    permiteAprovar,
    downloadPedidoDocument,
    idAprovacao,
    loadPedidoCompra,
    authorizationFunction,
  } = props;

  const [observacao, setObservacao] = React.useState(
    pedido?.aprovacao?.observacao || ""
  );

  React.useEffect(() => {
    async function fetchLoad() {
      await authorizationFunction(
        { idAcao: READ, idFuncionalidade: APROVACAO },
        () => loadPedidoCompra(idAprovacao)
      );
    }

    if (open) fetchLoad();
  }, [open]);

  if (!open) return null;

  const close = () =>
    history.push(`/${APROVACAO_ROUTE_PATH}/${LISTA_ROUTE_PATH}`);

  const moedaSimbolo = "R$";
  const identificador = pedido?.aprovacao?.identificador || null;
  const id = pedido?.id;

  return Object.keys(pedido)?.length > 0 ? (
    <Dialog maxWidth="xl" open={open} className="modalDialog" onClose={close}>
      <div className="modal-dialog modal-xl modal-fullscreen-2 modal-dialog-scrollable m-0 overflow-hidden">
        <div className="modal-content">
          <div className="modal-header p-4 d-flex flex-row align-items-start justify-content-between">
            <div className="d-flex flex-column">
              <span className="f-28 fw-500 m-0 p-color-imp lh-normal pr-3">
                {pedido?.patrimonioSeparado?.descricao}
              </span>
              <span className="f-22 m-0 mt-2 txt-blue-link-dark">
                Pedido de Compra
                {pedido?.patrimonioSeparado?.apelido &&
                  ` — ${pedido?.patrimonioSeparado?.apelido}`}
              </span>
            </div>
            <Close role="button" className="text-danger" onClick={close} />
          </div>

          <div className="modal-body px-4 py-0">
            <div className="text-dark f-16">
              {/*  */}
              <div className="p-color f-20 fw-500 my-2">Identificação</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">ID do Pedido</p>
                  <p className="fw-400 mb-0">{pedido?.identificador || "--"}</p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Tipo de Item</p>
                  <p className="fw-400 mb-0">
                    {pedido?.idTipoItem?.value || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Tipo de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {pedido?.idTipoPagamento?.value || "--"}
                  </p>
                </div>
                {pedido?.idTipoItem?.key == 1 && (
                  <div className="col-lg">
                    <p className="mb-1">Produto</p>
                    <p className="fw-400 mb-0">
                      {pedido?.produto?.nome || "--"}
                    </p>
                  </div>
                )}
                {pedido?.idTipoItem?.key == 2 && (
                  <div className="col-lg">
                    <p className="mb-1">Movimentação Financeira</p>
                    <p className="fw-400 mb-0">
                      {pedido?.movimentacaoFinanceira?.nome || "--"}
                    </p>
                  </div>
                )}
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 my-2">Alocação</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Conta Origem</p>
                  <p className="fw-400 mb-0">
                    {pedido?.contaOrigem?.nomeExibicao || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Origem de Recursos</p>
                  <p className="fw-400 mb-0">
                    {pedido?.origemRecurso?.descricao || "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg-12">
                  <p className="mb-1">Reembolsante</p>
                  <p className="fw-400 mb-0">
                    {`${
                      pedido?.reembolsante?.razaoSocial
                        ? pedido?.reembolsante?.razaoSocial
                        : "--"
                    } ${
                      pedido?.reembolsante?.cnpj
                        ? ` (CNPJ: ${formatCNPJ(pedido?.reembolsante?.cnpj)})`
                        : ""
                    }`}
                  </p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">
                Dados de Pagamento
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg-12">
                  <p className="mb-1">Fornecedor</p>
                  <p className="fw-400 mb-0">
                    {`${
                      pedido?.fornecedor?.razaoSocial
                        ? pedido?.fornecedor?.razaoSocial
                        : "--"
                    } ${
                      pedido?.fornecedor?.cnpj
                        ? ` (CNPJ: ${formatCNPJ(pedido?.fornecedor?.cnpj)})`
                        : ""
                    }`}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg-4">
                  <p className="mb-1">Forma de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {pedido?.idFormaPagamento?.value || "--"}
                  </p>
                </div>
                <div className="col-lg-4">
                  <p className="mb-1">Data de Pagamento</p>
                  <p className="fw-400 mb-0">
                    {pedido?.dataPrevisaoPagamento
                      ? getDateFormatedFromString(pedido?.dataPrevisaoPagamento)
                      : "--"}
                  </p>
                </div>
                <div className="col-lg-4">
                  <p className="mb-1">Valor Bruto</p>
                  <p className="fw-400 mb-0">
                    {pedido?.valorBruto
                      ? convertCurrencyNumber(
                          pedido?.valorBruto,
                          `${moedaSimbolo} `,
                          2
                        )
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <p className="mb-1">Descrição</p>
                  <p className="fw-400 mb-0">{pedido?.descricao || "--"}</p>
                </div>
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Documentos</div>
              <div className="column">
                {pedido?.documentoList?.length ? (
                  pedido?.documentoList?.map((doc, i) => {
                    return (
                      <div
                        key={i}
                        className="border brd-1 bg-grey-light text-dark w-100 rounded d-flex align-items-start flex-row mb-2 px-2 py-1"
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                          <div className="d-flex flex-row align-items-center">
                            <i
                              className="fa fa-file-pdf-o mr-2 mt-1px"
                              aria-hidden="true"
                            />
                            <p className="mb-0">{`${doc?.nomeArquivo}.${doc?.formatoArquivo}`}</p>
                          </div>
                          {doc?.nomeArquivo && (
                            <i
                              role="button"
                              className="fa fa-cloud-download mt-1px"
                              aria-hidden="true"
                              onClick={() => {
                                downloadPedidoDocument({
                                  idPedidoCompra: id,
                                  nomeArquivo: doc?.nomeArquivo,
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="fw-400 mb-0">Não há documentos</p>
                )}
              </div>

              {/*  */}
              <div className="p-color f-20 fw-500 mb-2 mt-4">Aprovação</div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Enviado por</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.solicitante?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora de Envio</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.dataSolicitacao
                      ? getDateFormatedFromStringWithTime(
                          pedido?.aprovacao?.dataSolicitacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.observacao || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">ID Aprovação</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.idFormatado || "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 1</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[0]?.aprovador?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[0]?.dataAprovacao
                      ? getDateFormatedFromStringWithTime(
                          pedido?.aprovacao?.aprovadores[0]?.dataAprovacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Status</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[0]?.statusAprovacao
                      ?.value || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[0]?.observacao || "--"}
                  </p>
                </div>
              </div>
              <div className="row w-100 mb-3">
                <div className="col-lg">
                  <p className="mb-1">Aprovador 2</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[1]?.aprovador?.nome || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Data e Hora Aprovação</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[1]?.dataAprovacao
                      ? getDateFormatedFromStringWithTime(
                          pedido?.aprovacao?.aprovadores[1]?.dataAprovacao
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Status</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[1]?.statusAprovacao
                      ?.value || "--"}
                  </p>
                </div>
                <div className="col-lg">
                  <p className="mb-1">Observação</p>
                  <p className="fw-400 mb-0">
                    {pedido?.aprovacao?.aprovadores[1]?.observacao || "--"}
                  </p>
                </div>
              </div>

              {permiteAprovar && (
                <>
                  <div className="p-color f-20 fw-500 mb-2 mt-4">
                    Observação
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 pr-0">
                      <textarea
                        className="w-100 rounded brd-1 p-2"
                        rows={7}
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {permiteAprovar && (
            <div className="modal-footer px-3 py-2 d-flex flex-row align-content-center justify-content-between m-0">
              <Button
                name={"Rejeitar Pedido"}
                action={async () => {
                  try {
                    await actionModal({
                      action: "reprovar",
                      identificador,
                      observacao,
                    });
                  } finally {
                    close();
                  }
                }}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
              <Button
                name={"Aprovar Pedido"}
                action={async () => {
                    try {
                      await actionModal({
                        action: "aprovar",
                        identificador,
                        observacao,
                      });
                    } finally {
                      close();
                    }
                }}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  pedido: state.aprovacaoReducer.pedidoCompraAprovacao,
  permiteAprovar: state.aprovacaoReducer.pedidoCompraAprovacao?.permiteAprovar,
});

const mapDispacthToProps = (dispatch) =>
  bindActionCreators(
    {
      actionModal,
      downloadPedidoDocument,
      loadPedidoCompra,
      authorizationFunction,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispacthToProps)(ModalPedidoCompra)
);
