/* eslint eqeqeq: 0 */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { __esModule, withRouter } from 'react-router-dom'
import Form from '../../../../common/form/Form'
import FormHeader from '../../../../common/form/FormHeader'
import FormContent from '../../../../common/form/FormContent'
import ConfirmDialog from '../../../../common/modal/ConfirmDialog'
import { validate } from '../validation/PedidosFormValidation'
import { showConfirmDialog } from '../../../../../actions/common/modal/ConfirmDialogAction'
import FixedFloaterErrors from '../../../../common/floats/FixedFloaterErrors'
import DropdownListInput from '../../../../common/input/DropdownListInput'
import Input from '../../../../common/input/Input'
import Textarea from '../../../../common/input/Textarea'
import MaskedInput from '../../../../common/input/MaskedInput'
import Button from '../../../../common/button/Button'
import { loadContasDestino } from '../../../../../actions/operacao/DespesaAction'
import { reduxForm, formValueSelector, Field, change, arrayInsert, arrayRemove } from 'redux-form'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { withStyles } from '@material-ui/core/styles'
import {
  mudaDependenteOnChange,
  showPedidosList,
  onDeleteDocument,
  onDropDocument,
  limpaDependentesOnChange,
  loadContratoDespesa,
  loadContaOrigem,
  loadContaDestino,
  downloadPedidoDocument,
  loadSeries,
  alteraCondicaoProtheus,
  alteraNumeroDocumento,
  getDespesa,
  loadValoresProtheus,
} from '../../../../../actions/pedidos/PedidosAction'
import { convertFileToBase64 } from '../../../../../utils/helpers/FileHelper'
import { useDropzone } from 'react-dropzone'
import { msgError } from '../../../../../utils/helpers/OthersHelpers'
import ModalCancelamentoPedido from '../modals/ModalCancelamentoPedido'
import ModalRevisaoCadastro from '../modals/ModalRevisaoCadastro'
import MaskedFormatInput from '../../../../common/input/MaskedFormatInput'
import ModalPagamentoPedido from '../modals/ModalPagamentoPedido'
import ReactTooltip from 'react-tooltip'

const styles = (theme) => ({
  parent: {
    width: '100%',
    marginTop: '0.5%',
    backgroundColor: 'transparent',
  },
  mrButton: {
    marginRight: theme.spacing.unit,
  },
  button: {
    color: '#630A37',
    '&:disabled': {
      color: '#D0D0D0',
    },
  },
  buttons: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '1rem !important',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  active: {},
  completed: {},
  customStepLabel: {
    "& [class*='MuiStepLabel-labelContainer']": {
      display: 'flex',
      justifyContent: 'center',
    },
    "& [class*='MuiTypography-root']": {
      width: 'max-content',
      padding: '0 10px',
      borderRadius: '20px',
      // fontSize: "12px",
      // textTransform: "uppercase",
      "&[class*='MuiStepLabel']": {
        backgroundColor: '#B8B8B8',
        color: '#121212c9',
        fontWeight: 500,
      },
      "&[class*='MuiStepLabel-active']": {
        backgroundColor: '#FFC107',
      },
      "&[class*='MuiStepLabel-completed']": {
        backgroundColor: '#83BF74',
        color: '#ffffff',
        '&.revisao': {
          backgroundColor: '#cc0000',
        },
        '&.exp_pendente': {
          backgroundColor: '#FFC107',
        },
      },
    },
  },
})

let PedidosForm = (props) => {
  const {
    handleSubmit,
    title,
    readOnly,
    showPedidosList,
    floatButtonVisible,
    showConfirmDialog,
    showCancelConfirmDialog,
    //
    invalid,
    messages,
    //
    classes,

    mudaDependenteOnChange,
    limpaDependentesOnChange,
    onDeleteDocument,
    onDropDocument,
    //
    pedidoCompra,
    documentos,
    idTipoPagamento,
    reembolsavel,
    idTipoItem,
    idFormaPagamento,
    loadContratoDespesa,
    loadContaOrigem,
    loadContaDestino,
    loadSeries,
    alteraCondicaoProtheus,
    dataEmissao,
    dataPrevisaoPagamento,
    alteraNumeroDocumento,
    getDespesa,
    loadValoresProtheus,
    //
    tipoItemList,
    tipoPagamentoList,
    produtoList,
    despesaList,
    contaOrigemList,
    origemRecursoList,
    reembolsanteList,
    fornecedorList,
    filteredFornecedorList,
    formaPagamentoList,
    contaDestinoList,
    naturezaList,
    tesList,
    patrimonioSeparadoList,
    documentoSuporteList,
    bancoEmissorList,
    movimentacaoFinanceiraList,
    serieList,
    downloadPedidoDocument,
    idPatrimonioSeparado,
    serie,
    idProduto,
    idDespesa,
    idFornecedor,
    valorBruto,
    idOrigemRecurso,
    enviarPagamentoSCD,
    exportacaoPendente,
    idContaOrigem,
  } = props

  const isOrigination = pedidoCompra?.aprovacao?.solicitante?.nome === 'Originação'

  const isSCD = ['535'].includes(idContaOrigem?.codigoBanco) || ['535'].includes(contaOrigemList.find((item) => item.id == idContaOrigem)?.codigoBanco || '')

  const [listFornecedores, setListFornecedores] = React.useState([])

  React.useEffect(() => {
    if (idFornecedor) {
      const newListFornecedores = fornecedorList.filter((obj) => obj.id == idFornecedor)
      setListFornecedores(newListFornecedores?.length ? newListFornecedores : fornecedorList)
    } else {
      setListFornecedores(fornecedorList)
    }
  }, [fornecedorList, idFornecedor])

  const [isDragging, setIsDragging] = React.useState(false)
  const [openCancelamento, setOpenCancelamento] = React.useState(false)
  const [openRevisar, setOpenRevisar] = React.useState(false)
  const [openPagar, setOpenPagar] = React.useState(false)
  const [loadingContrato, setLoadingContrato] = React.useState(false)
  const [loadingContaOrigem, setLoadingContaOrigem] = React.useState(false)
  const [loadingContaDestino, setLoadingContaDestino] = React.useState(false)
  const [loadingSerie, setLoadingSerie] = React.useState(false)

  const statusValue = pedidoCompra?.status?.key
  const hasApproval = pedidoCompra?.aprovacao

  const user = JSON.parse(localStorage.getItem('userLogged'))
  const areas = user.areasAtuacoes
  const permitidoCancelar = areas.some(({ id }) => id === 8 || id === 9)
  const isTreasury = areas.some(({ id }) => id === 9)

  const arrAprovador = [3, 4, 5, 6, 8]
  const ON_APROVADO = arrAprovador.includes(statusValue)
  const arrEscriturado = [4, 5, 6, 8]
  const ON_ESCRITURADO = arrEscriturado.includes(statusValue)

  const APROVADO = statusValue == 3
  const REVISAO = statusValue == 7
  const CANCELADO = statusValue == 8
  const EXP_PENDENTE = exportacaoPendente

  const NAO_SE_APLICA = idOrigemRecurso == 20 || idOrigemRecurso?.id == 20

  let renderStatus = [
    {
      key: 1,
      value: 'Cadastro Pendente',
      raw: 'Cadastro Pendente',
      show: true,
      concluidos: [1],
    },
    {
      key: 2,
      value: 'Cadastrado',
      raw: 'Cadastrado',
      show: true,
      concluidos: [1, 2],
    },
    {
      key: REVISAO ? 7 : 3,
      value: `${REVISAO ? 'A Revisar' : 'Aprovado'}`,
      raw: `${REVISAO ? 'ARevisar' : 'Aprovado'}`,
      show: true,
      concluidos: [1, 2, 3],
    },
    {
      key: 4,
      value: 'Escriturado',
      raw: 'Escriturado',
      show: true,
      concluidos: [1, 2, 3, 4],
    },
    {
      key: 5,
      value: 'Exportado',
      raw: 'Exportado',
      show: true,
      concluidos: [1, 2, 3, 4, 5],
    },
    {
      key: CANCELADO ? 8 : 6,
      value: `${CANCELADO ? 'Cancelado' : 'Pago'}`,
      raw: `${CANCELADO ? 'Cancelado' : 'Pago'}`,
      show: true,
      concluidos: [1, 2, 3, 4, 5, 6],
    },
  ]

  const steps = renderStatus.filter((sts) => sts.show).map((sts) => sts.value)
  const concluidos = renderStatus.filter((sts) => sts.key === statusValue).flatMap((sts) => sts.concluidos)

  const isOrdem = idTipoItem?.key == 1
  const isMoviment = idTipoItem?.key == 2

  const isRecorrente = idTipoPagamento?.key == 3

  const isTransf = idFormaPagamento?.key == 1
  const isBoleto = idFormaPagamento?.key == 2

  const pagamentoList = isOrdem || !idTipoItem ? tipoPagamentoList : isMoviment ? tipoPagamentoList.filter((tipo) => tipo?.key == 1) : null

  function CustomStepIcon({ active, completed, classes }) {
    const emRevisao = classes?.completed?.includes('revisao')
    const expPendente = classes?.completed?.includes('exp_pendente')
    const cRevi = '#cc0000'
    const cComp = '#83BF74'
    const cActv = '#FFC107'
    const cDflt = '#888888'

    return emRevisao ? (
      <RadioButtonChecked style={{ color: cRevi }} />
    ) : expPendente ? (
      <CheckCircleOutline style={{ color: cActv }} />
    ) : completed ? (
      <CheckCircleOutline style={{ color: cComp }} />
    ) : active ? (
      <RadioButtonChecked style={{ color: cActv }} />
    ) : (
      <RadioButtonUnchecked style={{ color: cDflt }} />
    )
  }

  // UPLOAD DOCUMENTS

  const onDrop = async (acceptedFiles) => {
    const files = acceptedFiles

    let arquivos = await Promise.all(
      files.map(async (file) => ({
        nomeArquivo: file.name,
        idPedidoCompra: pedidoCompra?.id || null,
        arquivoAnexado: await convertFileToBase64(file),
        formatoArquivo: file?.name?.split('.').pop().toLowerCase() || '',
      }))
    )

    onDropDocument(arquivos)
    setIsDragging(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    onDropRejected: (rejected) => {
      const names = rejected.map((item) => item.file.name)
      names.forEach((name) => msgError(`Arquivo (${name}) inválido`))
    },
    disabled: readOnly && !APROVADO,
  })

  // -----

  return (
    <Form>
      <FormHeader title={title} col={'col-md'} offSet={20}></FormHeader>

      <Stepper className="my-3" classes={{ root: classes.stepper }} activeStep={CANCELADO || REVISAO ? null : concluidos.length || 0} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index} completed={concluidos.some((et) => et === index + 1)}>
            <StepLabel
              data-type="opea"
              data-for="custom-work"
              data-tip={index + 1 == 5 && EXP_PENDENTE ? 'Pagamento via Opea SCD, aguardando exportação para o Protheus' : ''}
              completed={concluidos.some((et) => et === index + 1)}
              className={classes.customStepLabel}
              StepIconComponent={CustomStepIcon}
              classes={{
                completed: `
                    ${classes.completed} 
                    ${((REVISAO && index + 1 == 3) || (CANCELADO && index + 1 == 6)) && 'revisao'} 
                    ${index + 1 == 5 && EXP_PENDENTE && 'exp_pendente'} 
                  `,
              }}
              StepIconProps={{
                classes: {
                  completed: `
                      ${classes.completed} 
                      ${((REVISAO && index + 1 == 3) || (CANCELADO && index + 1 == 6)) && 'revisao'} 
                      ${index + 1 == 5 && EXP_PENDENTE && 'exp_pendente'} 
                    `,
                  active: classes.active,
                  disabled: classes.disabled,
                },
              }}
            >
              <span>{step}</span>
            </StepLabel>
            <ReactTooltip place="bottom" effect="solid" id="custom-work" globalEventOff="click" className="tooltip-custom px-2 py-1" />
          </Step>
        ))}
      </Stepper>

      <FormContent handleSubmit={handleSubmit}>
        <div className="row">
          {messages?.hasError && <FixedFloaterErrors messages={messages} title="Status do Cadastro" />}

          {/* Status */}
          {Boolean(hasApproval) && (
            <div className="col-12">
              <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                <span className="h3 page-head__title">Status</span>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.dataSolicitacao"
                      name="pedidoCompra.aprovacao.dataSolicitacao"
                      label="Data de Cadastro"
                      type={'date'}
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.solicitante.nome"
                      name="pedidoCompra.aprovacao.solicitante.nome"
                      label="Solicitante"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field component={Input} inputId="pedidoCompra.aprovacao.idFormatado" name="pedidoCompra.aprovacao.idFormatado" label="ID Aprovação" readOnly={true} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.aprovadores[0].aprovador.nome"
                      name="pedidoCompra.aprovacao.aprovadores[0].aprovador.nome"
                      label="Aprovador 1"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.aprovadores[0].statusAprovacao.value"
                      name="pedidoCompra.aprovacao.aprovadores[0].statusAprovacao.value"
                      label="Status Aprovação 1"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.aprovadores[0].observacao"
                      name="pedidoCompra.aprovacao.aprovadores[0].observacao"
                      label="Comentário Aprovador 1"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.aprovadores[1].aprovador.nome"
                      name="pedidoCompra.aprovacao.aprovadores[1].aprovador.nome"
                      label="Aprovador 2"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.aprovadores[1].statusAprovacao.value"
                      name="pedidoCompra.aprovacao.aprovadores[1].statusAprovacao.value"
                      label="Status Aprovação 2"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.aprovacao.aprovadores[1].observacao"
                      name="pedidoCompra.aprovacao.aprovadores[1].observacao"
                      label="Comentário Aprovador 2"
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Identificação */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Identificação</span>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idTipoItem"
                    name="pedidoCompra.idTipoItem"
                    label="Tipo de Item"
                    options={tipoItemList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={(e) => {
                      limpaDependentesOnChange([
                        'pedidoCompra.idProduto',
                        'pedidoCompra.idMovimentacaoFinanceira',
                        'pedidoCompra.idDespesa',
                        // "pedidoCompra.idOperacao",
                        'pedidoCompra.idTipoPagamento',
                        'pedidoCompra.dataEmissao',
                        'pedidoCompra.idBancoEmissor',
                        'pedidoCompra.documentoSuporte',
                        'pedidoCompra.numeroDocumento',
                        'pedidoCompra.codigoBarras',
                      ])

                      if (e?.key == 2) {
                        mudaDependenteOnChange('pedidoCompra.idTipoPagamento', pagamentoList[0])
                      }
                    }}
                  />
                </div>

                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idTipoPagamento"
                    name="pedidoCompra.idTipoPagamento"
                    label="Tipo de Pagamento"
                    options={pagamentoList}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly || isMoviment}
                    required
                    onChange={(e) => {
                      limpaDependentesOnChange(['pedidoCompra.idDespesa'])
                    }}
                  />
                </div>

                {isOrdem && (
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="pedidoCompra.idProduto"
                      name="pedidoCompra.idProduto"
                      label="Produto"
                      options={produtoList}
                      textField="nome"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly}
                      required
                      onChange={async (e) => {
                        limpaDependentesOnChange([
                          'pedidoCompra.idDespesa',
                          'pedidoCompra.idContaOrigem',
                          'pedidoCompra.idOrigemRecurso',
                          'pedidoCompra.reembolsavel',
                          'pedidoCompra.idReembolsante',
                          'pedidoCompra.idFornecedor',
                        ])

                        if (e?.id && serie && (idPatrimonioSeparado?.id || idPatrimonioSeparado)) {
                          let url = `?idProduto=${e.id}&idOperacao=${serie?.id}&idPatrimonioSeparado=${idPatrimonioSeparado?.id || idPatrimonioSeparado}`

                          setLoadingContrato(true)
                          await loadContratoDespesa(url, isRecorrente)
                          setLoadingContrato(false)
                        }
                      }}
                    />
                  </div>
                )}

                {isMoviment && (
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="pedidoCompra.idMovimentacaoFinanceira"
                      name="pedidoCompra.idMovimentacaoFinanceira"
                      label="Tipo de Movimentação"
                      options={movimentacaoFinanceiraList}
                      textField="nome"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="pedidoCompra.patrimonioSeparado.codigoFilial"
                    name="pedidoCompra.patrimonioSeparado.codigoFilial"
                    label="Código Filial"
                    readOnly={true}
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="pedidoCompra.pedidoCompraArquivoExportacao.arquivoExportacao"
                    name="pedidoCompra.pedidoCompraArquivoExportacao.arquivoExportacao"
                    label="ID Exportação"
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Alocação */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Alocação</span>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idPatrimonioSeparado"
                    name="pedidoCompra.idPatrimonioSeparado"
                    label="Patrimônio Separado"
                    options={patrimonioSeparadoList}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={async (e) => {
                      if (e?.id) {
                        mudaDependenteOnChange('pedidoCompra.idOperacao')
                        mudaDependenteOnChange('pedidoCompra.idContaOrigem')
                        mudaDependenteOnChange('pedidoCompra.idDespesa')

                        setLoadingSerie(true)
                        await loadSeries(e?.id)
                        setLoadingSerie(false)

                        if (serie && idProduto) {
                          let url = `?idPatrimonioSeparado=${e.id}&idOperacao=${serie?.id || serie}&idProduto=${idProduto?.id || idProduto}`

                          setLoadingContrato(true)
                          await loadContratoDespesa(url, isRecorrente)
                          setLoadingContrato(false)
                        }

                        setLoadingContaOrigem(true)
                        await loadContaOrigem(e?.id, serie?.id || serie, idProduto?.id || idProduto)
                        setLoadingContaOrigem(false)
                      }
                    }}
                  />
                </div>

                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idOperacao"
                    name="pedidoCompra.idOperacao"
                    label="Série"
                    options={serieList}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly || loadingSerie}
                    required
                    onChange={async (e) => {
                      mudaDependenteOnChange('pedidoCompra.idContaOrigem')
                      mudaDependenteOnChange('pedidoCompra.idDespesa')

                      if (e?.id) {
                        if (idProduto && (idPatrimonioSeparado?.id || idPatrimonioSeparado)) {
                          let url = `?idOperacao=${e.id}&idProduto=${idProduto?.id || idProduto}&idPatrimonioSeparado=${idPatrimonioSeparado?.id || idPatrimonioSeparado}`

                          setLoadingContrato(true)
                          await loadContratoDespesa(url, isRecorrente)
                          setLoadingContrato(false)
                        }

                        setLoadingContaOrigem(true)
                        await loadContaOrigem(idPatrimonioSeparado?.id || idPatrimonioSeparado, e?.id, idProduto?.id || idProduto)
                        setLoadingContaOrigem(false)
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row">
                {isOrdem && isRecorrente && (
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="pedidoCompra.idDespesa"
                      name="pedidoCompra.idDespesa"
                      label="Contrato de Despesa"
                      options={despesaList}
                      textField="nomeExibicao"
                      valueField="idDespesa"
                      filter="contains"
                      readOnly={readOnly || loadingContrato}
                      required
                      onChange={(e) => {
                        limpaDependentesOnChange([
                          'pedidoCompra.idContaOrigem',
                          'pedidoCompra.idOrigemRecurso',
                          'pedidoCompra.reembolsavel',
                          'pedidoCompra.idReembolsante',
                          'pedidoCompra.idFornecedor',
                        ])

                        if (e?.idDespesa) getDespesa(e?.idDespesa, origemRecursoList)
                      }}
                    />
                  </div>
                )}

                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idContaOrigem"
                    name="pedidoCompra.idContaOrigem"
                    label="Conta Origem"
                    options={contaOrigemList}
                    textField="nomeExibicao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly || loadingContaOrigem || Boolean(idDespesa)}
                    required
                    onChange={() => mudaDependenteOnChange('pedidoCompra.idFormaPagamento', null)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idOrigemRecurso"
                    name="pedidoCompra.idOrigemRecurso"
                    label="Origem de Recursos"
                    options={origemRecursoList}
                    textField="descricao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly || Boolean(idDespesa)}
                    required
                    onChange={(e) => {
                      mudaDependenteOnChange('pedidoCompra.escrituracao.carimbo', e?.carimbo)
                      if (e?.id == 20) mudaDependenteOnChange('pedidoCompra.reembolsavel', 'Sim')
                    }}
                  />
                </div>

                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.reembolsavel"
                    name="pedidoCompra.reembolsavel"
                    label="Despesa Reembolsável?"
                    readOnly={readOnly || Boolean(idDespesa) || NAO_SE_APLICA}
                    required
                    onChange={() => mudaDependenteOnChange('pedidoCompra.idReembolsante')}
                  />
                </div>

                {reembolsavel == 'Sim' && (
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="pedidoCompra.idReembolsante"
                      name="pedidoCompra.idReembolsante"
                      label="Reembolsante"
                      options={reembolsanteList}
                      textField="nomeExibicao"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || Boolean(idDespesa)}
                      required
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Dados de Pagamento */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Dados de Pagamento</span>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idFornecedor"
                    name="pedidoCompra.idFornecedor"
                    label="Fornecedor"
                    options={filteredFornecedorList || listFornecedores}
                    textField="nomeExibicao"
                    valueField="id"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={async (e) => {
                      mudaDependenteOnChange('pedidoCompra.idContaDestino')
                      mudaDependenteOnChange('pedidoCompra.escrituracao.codigoFornecedor', e?.codigoFornecedor || null)

                      setLoadingContaDestino(true)
                      if (isTransf && e?.cnpj) await loadContaDestino(e?.id)
                      setLoadingContaDestino(false)
                    }}
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={DropdownListInput}
                    inputId="pedidoCompra.idFormaPagamento"
                    name="pedidoCompra.idFormaPagamento"
                    label="Forma de Pagamento"
                    options={formaPagamentoList}
                    {...(isSCD ? { disabled: [2] } : {})}
                    textField="value"
                    valueField="key"
                    filter="contains"
                    readOnly={readOnly}
                    required
                    onChange={async (e) => {
                      mudaDependenteOnChange('pedidoCompra.idContaDestino')

                      setLoadingContaDestino(true)
                      if (e?.key == 1 && idFornecedor) await loadContaDestino(idFornecedor?.id || idFornecedor)
                      setLoadingContaDestino(false)
                    }}
                  />
                </div>

                {idFormaPagamento?.key == 1 && (
                  <div className="col-lg">
                    <Field
                      component={DropdownListInput}
                      inputId="pedidoCompra.idContaDestino"
                      name="pedidoCompra.idContaDestino"
                      label="Conta Destino"
                      options={contaDestinoList}
                      textField="tipoConta"
                      valueField="id"
                      filter="contains"
                      readOnly={readOnly || loadingContaDestino}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="row">
                {((ON_APROVADO && isOrdem) || (isMoviment && isBoleto)) && (
                  <>
                    <div className="col-lg">
                      <Field
                        component={DropdownListInput}
                        inputId="pedidoCompra.documentoSuporte"
                        name="pedidoCompra.documentoSuporte"
                        label="Documento Suporte"
                        options={documentoSuporteList}
                        textField="value"
                        valueField="key"
                        filter="contains"
                        readOnly={readOnly && !APROVADO}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={MaskedFormatInput}
                        maxLength="9"
                        inputId="pedidoCompra.numeroDocumento"
                        name="pedidoCompra.numeroDocumento"
                        label="Número do Documento"
                        readOnly={readOnly && !APROVADO}
                        required
                        onBlur={(e_, v) => {
                          if (v) alteraNumeroDocumento(v, 9)
                        }}
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={MaskedFormatInput}
                        inputId="pedidoCompra.codigoBarras"
                        name="pedidoCompra.codigoBarras"
                        label="Código de Barras do Boleto"
                        readOnly={readOnly && !APROVADO}
                        required={isBoleto}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                {((ON_APROVADO && isOrdem) || (isMoviment && isBoleto)) && (
                  <>
                    <div className="col-lg">
                      <Field
                        component={DropdownListInput}
                        inputId="pedidoCompra.idBancoEmissor"
                        name="pedidoCompra.idBancoEmissor"
                        label="Banco Emissor"
                        options={bancoEmissorList}
                        textField="nomeExibicao"
                        valueField="id"
                        filter="contains"
                        readOnly={readOnly && !APROVADO}
                        required={isBoleto}
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={Input}
                        inputId="pedidoCompra.dataEmissao"
                        name="pedidoCompra.dataEmissao"
                        label="Data de Emissão"
                        type={'date'}
                        readOnly={readOnly && !APROVADO}
                        required
                        onChange={async (e_, v) => {
                          if (v && dataPrevisaoPagamento) {
                            mudaDependenteOnChange('pedidoCompra.escrituracao.condicaoProtheus')
                            alteraCondicaoProtheus(v, dataPrevisaoPagamento)
                          }
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="col-lg">
                  <Field
                    component={Input}
                    inputId="pedidoCompra.dataPrevisaoPagamento"
                    name="pedidoCompra.dataPrevisaoPagamento"
                    label="Data de Pagamento"
                    type={'date'}
                    readOnly={readOnly && !(statusValue === 3 && isTreasury)}
                    required
                    onBlur={(e) => e.preventDefault()}
                    onChange={async (e_, v) => {
                      if (v && dataEmissao) {
                        mudaDependenteOnChange('pedidoCompra.escrituracao.condicaoProtheus')
                        alteraCondicaoProtheus(dataEmissao, v)
                      }
                    }}
                  />
                </div>
                <div className="col-lg">
                  <Field
                    component={MaskedInput}
                    inputId="pedidoCompra.valorBruto"
                    name="pedidoCompra.valorBruto"
                    label="Valor Bruto"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    prefix="R$ "
                    fixedDecimalScale
                    readOnly={readOnly && !(statusValue === 3 && isTreasury)}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg">
                  <Field
                    component={Textarea}
                    inputId="pedidoCompra.descricao"
                    name="pedidoCompra.descricao"
                    type="text"
                    rows={1000}
                    minHeight={5}
                    maxLength={254}
                    label="Descrição"
                    readOnly={readOnly}
                    required
                    trimOnBlur
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Dados do Protheus */}
          {((ON_APROVADO && isOrdem) || (ON_ESCRITURADO && isMoviment)) && (
            <div className="col-12">
              <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
                <span className="h3 page-head__title">Dados do Protheus</span>
                {!(ON_ESCRITURADO && isMoviment) && (
                  <div className="row">
                    <div className="col-lg">
                      <Field
                        component={DropdownListInput}
                        inputId="pedidoCompra.escrituracao.idNatureza"
                        name="pedidoCompra.escrituracao.idNatureza"
                        label="Natureza"
                        options={naturezaList}
                        textField="naturezaProtheus"
                        valueField="id"
                        filter="contains"
                        readOnly={readOnly && !APROVADO}
                        required
                        onChange={(e) => {
                          mudaDependenteOnChange('pedidoCompra.escrituracao.codigoNatureza', e?.codigoNatureza || null)
                          mudaDependenteOnChange('pedidoCompra.valorLiquido', null)
                          mudaDependenteOnChange('pedidoCompra.valorImposto', null)
                          if (e?.id && valorBruto) loadValoresProtheus(e?.id, valorBruto)
                        }}
                      />
                    </div>

                    <div className="col-lg">
                      <Field
                        component={DropdownListInput}
                        inputId="pedidoCompra.escrituracao.idTes"
                        name="pedidoCompra.escrituracao.idTes"
                        label="TES"
                        options={tesList}
                        textField="value"
                        valueField="key"
                        filter="contains"
                        readOnly={readOnly && !APROVADO}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={MaskedInput}
                        inputId="pedidoCompra.valorLiquido"
                        name="pedidoCompra.valorLiquido"
                        label="Valor Líquido"
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        prefix="R$ "
                        fixedDecimalScale
                        readOnly={true}
                        required
                      />
                    </div>
                    <div className="col-lg">
                      <Field
                        component={MaskedInput}
                        inputId="pedidoCompra.valorImposto"
                        name="pedidoCompra.valorImposto"
                        label="Imposto Descontado"
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        prefix="R$ "
                        readOnly={true}
                        required
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.escrituracao.codigoNatureza"
                      name="pedidoCompra.escrituracao.codigoNatureza"
                      label="Código Natureza"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field component={Input} inputId="pedidoCompra.escrituracao.carimbo" name="pedidoCompra.escrituracao.carimbo" label="Carimbo" readOnly={true} required />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.escrituracao.codigoFornecedor"
                      name="pedidoCompra.escrituracao.codigoFornecedor"
                      label="Código Fornecedor"
                      readOnly={true}
                      required
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.escrituracao.condicaoProtheus"
                      name="pedidoCompra.escrituracao.condicaoProtheus"
                      label="Condição Protheus"
                      readOnly={true}
                    />
                  </div>
                  <div className="col-lg">
                    <Field
                      component={Input}
                      inputId="pedidoCompra.escrituracao.pagamentoProtheus"
                      name="pedidoCompra.escrituracao.pagamentoProtheus"
                      label="Pagamento Protheus"
                      readOnly={true}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Documentos */}
          <div className="col-12">
            <div className="column shadow-custom bg-white rounded-12 col-12 mb-4 p-4">
              <span className="h3 page-head__title">Documentos</span>

              <div
                {...getRootProps()}
                className="w-100 d-flex flex-column justify-content-start align-items-center text-center position-relative p-2 rounded my-2"
                style={{
                  minHeight: '100px',
                  maxHeight: '200px',
                  transition: 'border 0.3s ease-out',
                  border: isDragging ? '2px dashed #630A37' : `${readOnly ? '1px solid #B8B8B8' : '2px dashed #cccccc'}`,
                }}
              >
                <input {...getInputProps()} />
                {(!readOnly || (readOnly && APROVADO)) && (
                  <p className="py-2 mb-0">
                    Arraste os arquivos ou{' '}
                    <u className="btn-link-opea" role="button">
                      clique aqui
                    </u>{' '}
                    para upload
                  </p>
                )}
                <div className="d-flex flex-column h-100 overflow-auto w-100 p-2">
                  {documentos?.length > 0 &&
                    documentos?.map((file, i) => {
                      return (
                        <div
                          key={`file-${i}`}
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            backgroundColor: '#E7E7E7',
                            border: '1px solid #B8B8B8',
                          }}
                          className="p-2 d-flex flex-row align-items-center justify-content-between rounded fw-400 my-1"
                        >
                          <span>{file.nomeArquivo || file.name}</span>
                          <div className="d-flex flex-row">
                            {file?.nomeArquivo && pedidoCompra?.id && (
                              <i
                                role="button"
                                className="fa fa-cloud-download f-20 mr-3"
                                onClick={() =>
                                  downloadPedidoDocument({
                                    idPedidoCompra: pedidoCompra?.id,
                                    nomeArquivo: file?.nomeArquivo,
                                  })
                                }
                              ></i>
                            )}

                            {(!readOnly || (readOnly && APROVADO)) && (
                              <i role="button" onClick={() => onDeleteDocument(i)} className="fa fa-minus-circle f-20 d-flex align-items-center mr-1"></i>
                            )}
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* --- ACTIONS --- */}
        <div className="col-12 px-0">
          <div className="d-flex flex-row justify-content-between align-items-center col-12 mb-4 mt-2 p-0">
            <Button
              name={'Voltar Tela'}
              classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
              action={() => {
                floatButtonVisible ? showConfirmDialog(true) : showPedidosList()
              }}
            />

            {(!statusValue || statusValue === 1 || (statusValue === 3 && isTreasury) || statusValue === 7) && (
              <Button
                type="submit"
                name={'Salvar Alterações'}
                classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  mudaDependenteOnChange('pedidoCompra.typeSubmit')

                  invalid && msgError('Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.')
                }}
              />
            )}

            {!isOrigination && statusValue >= 2 && statusValue <= 5 && (
              <Button
                tooltipType="opea"
                name={'Cancelar Pedido'}
                action={() => setOpenCancelamento(true)}
                tooltip={!permitidoCancelar && statusValue >= 3 && statusValue <= 5}
                disabled={!permitidoCancelar && statusValue >= 3 && statusValue <= 5}
                classes="err-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                tooltipTip="Somente usuários de Tesouraria ou Operations têm permissão para cancelar a partir deste ponto"
              />
            )}

            {!isOrigination && (statusValue == 2 || statusValue == 3) && (
              <Button name={'Revisar Cadastro'} classes="warn-bg-imp text-dark px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0" action={() => setOpenRevisar(true)} />
            )}

            {statusValue == 5 && (
              <Button name={'Confirmar Pagamento'} classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0" action={() => setOpenPagar(true)} />
            )}

            {statusValue == 3 && !enviarPagamentoSCD && (
              <Button
                type="submit"
                name={'Enviar para Exportação'}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  mudaDependenteOnChange('pedidoCompra.typeSubmit', 'enviarExportacao')

                  invalid && msgError('Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.')
                }}
              />
            )}

            {statusValue == 3 && enviarPagamentoSCD && (
              <Button
                type="submit"
                name={'Enviar para Pagamento'}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  mudaDependenteOnChange('pedidoCompra.typeSubmit', 'enviarPagamento')

                  invalid && msgError('Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.')
                }}
              />
            )}

            {(!statusValue || statusValue == 1 || statusValue == 7) && (
              <Button
                type="submit"
                name={'Enviar para Aprovação'}
                classes="scs-bg-imp text-white px-2-5 f-14 py-1-5 text-capitalize rounded btn-search m-0"
                action={() => {
                  mudaDependenteOnChange('pedidoCompra.typeSubmit')
                  mudaDependenteOnChange('pedidoCompra.enviarAprovacao', true)

                  invalid && msgError('Alguns erros foram encontrados. Por favor, revise atentamente o cadastro.')
                }}
              />
            )}
          </div>
        </div>
      </FormContent>
      <ConfirmDialog
        title="Aviso"
        contentText="Os dados do formulário serão perdidos, deseja realmente sair?"
        textButtonCancel="CANCELAR"
        textButtonConfirm="CONFIRMAR"
        showDialog={showCancelConfirmDialog}
        onClose={() => showConfirmDialog(false)}
        onConfirm={() => showPedidosList()}
        type="warning"
        maxWidth="md"
      />

      {statusValue >= 2 && statusValue <= 5 && <ModalCancelamentoPedido item={pedidoCompra} open={openCancelamento} close={() => setOpenCancelamento(false)} />}

      {(statusValue == 2 || statusValue == 3) && <ModalRevisaoCadastro item={pedidoCompra} open={openRevisar} close={() => setOpenRevisar(false)} />}

      {statusValue == 5 && <ModalPagamentoPedido item={pedidoCompra} open={openPagar} close={() => setOpenPagar(false)} />}
    </Form>
  )
}

const selector = formValueSelector('PedidosForm')

const mapStateToProps = (state) => ({
  operacao: selector(state, 'operacao'),
  operacoes: state.operacaoReducer.selectedOperacoes || [],
  showCancelConfirmDialog: state.confirmDialogReducer.showConfirmDialog,
  pedidoCompra: state.pedidosReducer.pedidosFormTypes.pedidoCompra || [],
  documentos: selector(state, 'pedidoCompra.documentoList') || [],
  idTipoPagamento: selector(state, 'pedidoCompra.idTipoPagamento') || [],
  reembolsavel: selector(state, 'pedidoCompra.reembolsavel') || [],
  idTipoItem: selector(state, 'pedidoCompra.idTipoItem') || null,
  idFormaPagamento: selector(state, 'pedidoCompra.idFormaPagamento') || [],
  dataEmissao: selector(state, 'pedidoCompra.dataEmissao') || null,
  dataPrevisaoPagamento: selector(state, 'pedidoCompra.dataPrevisaoPagamento') || [],
  idPatrimonioSeparado: selector(state, 'pedidoCompra.idPatrimonioSeparado') || null,
  serie: selector(state, 'pedidoCompra.idOperacao') || null,
  idProduto: selector(state, 'pedidoCompra.idProduto') || null,
  idDespesa: selector(state, 'pedidoCompra.idDespesa') || null,
  idFornecedor: selector(state, 'pedidoCompra.idFornecedor') || null,
  valorBruto: selector(state, 'pedidoCompra.valorBruto') || null,
  idOrigemRecurso: selector(state, 'pedidoCompra.idOrigemRecurso') || null,
  enviarPagamentoSCD: selector(state, 'pedidoCompra.enviarPagamentoSCD') || null,
  exportacaoPendente: selector(state, 'pedidoCompra.exportacaoPendente') || null,
  idContaOrigem: selector(state, 'pedidoCompra.idContaOrigem') || null,
  // LISTS
  tipoItemList: state.pedidosReducer.pedidosFormTypes.tipoItemList || [],
  tipoPagamentoList: state.pedidosReducer.pedidosFormTypes.tipoPagamentoList || [],
  produtoList: state.pedidosReducer.pedidosFormTypes.produtoList || [],
  despesaList: state.pedidosReducer.pedidosFormTypes.despesaList || [],
  contaOrigemList: state.pedidosReducer.pedidosFormTypes.contaOrigemList || [],
  origemRecursoList: state.pedidosReducer.pedidosFormTypes.origemRecursoList || [],
  reembolsanteList: state.pedidosReducer.pedidosFormTypes.reembolsanteList || [],
  fornecedorList: state.pedidosReducer.pedidosFormTypes.fornecedorList || [],
  filteredFornecedorList: state.pedidosReducer.pedidosFormTypes.filteredFornecedorList || null,
  formaPagamentoList: state.pedidosReducer.pedidosFormTypes.formaPagamentoList || [],
  contaDestinoList: state.pedidosReducer.pedidosFormTypes.contaDestinoList || [],
  naturezaList: state.pedidosReducer.pedidosFormTypes.naturezaList || [],
  tesList: state.pedidosReducer.pedidosFormTypes.tesList || [],
  patrimonioSeparadoList: state.pedidosReducer.pedidosFormTypes.patrimonioSeparadoList || [],
  documentoSuporteList: state.pedidosReducer.pedidosFormTypes.documentoSuporteList || [],
  bancoEmissorList: state.pedidosReducer.pedidosFormTypes.bancoEmissorList || [],
  movimentacaoFinanceiraList: state.pedidosReducer.pedidosFormTypes.movimentacaoFinanceiraList || [],
  serieList: state.pedidosReducer.pedidosFormTypes.serieList || [],
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showPedidosList,
      showConfirmDialog,
      arrayInsert,
      arrayRemove,
      change,
      mudaDependenteOnChange,
      loadContasDestino,
      onDeleteDocument,
      onDropDocument,
      limpaDependentesOnChange,
      loadContratoDespesa,
      loadContaOrigem,
      loadContaDestino,
      downloadPedidoDocument,
      loadSeries,
      alteraCondicaoProtheus,
      alteraNumeroDocumento,
      getDespesa,
      loadValoresProtheus,
    },
    dispatch
  )

const ConnectedPedidosForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PedidosForm)))
export default reduxForm({ form: 'PedidosForm', validate })(ConnectedPedidosForm)
