/* eslint eqeqeq: 0 */

import { dataMaiorOuIgualA, dataMaiorQue, dataMenorOuIgualAHoje, dataMenorQueHoje } from '../../../../../utils/helpers/DateTimeHelper'
import { convertCurrencyToNumber } from '../../../../../utils/helpers/NumberHelper'

// const currEnv = localStorage.getItem("currEnv") ?? localStorage.getItem("currEnv");
// const gestora = currEnv == process.env.REACT_APP_ENV_GEST_ID;

export const validate = (values) => {
  const pedido = values.pedidoCompra

  const isMoviment = pedido?.idTipoItem?.key == 2

  const isRecorrente = pedido?.idTipoPagamento?.key == 3
  const valorBrutoNormalized = convertCurrencyToNumber(pedido?.valorBruto)
  const statusKey = pedido?.status?.key

  const APROVADO = statusKey == 3
  const isBoleto = pedido?.idFormaPagamento?.key == 2

  let errors = {
    pedidoCompra: {
      idTipoItem: !pedido?.idTipoItem ? 'Preenchimento obrigatório' : null,
      idTipoPagamento: !pedido?.idTipoPagamento ? 'Preenchimento obrigatório' : null,
      idProduto: !pedido?.idProduto ? 'Preenchimento obrigatório' : null,
      idMovimentacaoFinanceira: !pedido?.idMovimentacaoFinanceira ? 'Preenchimento obrigatório' : null,
      reembolsavel: !pedido?.reembolsavel ? 'Preenchimento obrigatório' : null,
      idReembolsante: pedido?.reembolsavel == 'Sim' && !pedido?.idReembolsante ? 'Preenchimento obrigatório' : null,
      dataPrevisaoPagamento: !pedido?.dataPrevisaoPagamento
        ? 'Preenchimento obrigatório'
        : dataMenorOuIgualAHoje(pedido?.dataPrevisaoPagamento)
        ? 'Data de pagamento deve ser maior que a data de hoje'
        : null,
      // despesaSerie: !pedido?.despesaSerie ? "Preenchimento obrigatório" : null,
      // idSerie: ((isRecorrente && pedido?.despesaSerie == "Sim") || (!isRecorrente && pedido?.despesaSerie)) && !pedido?.idSerie ? "Preenchimento obrigatório" : null,
      idPatrimonioSeparado: !pedido?.idPatrimonioSeparado ? 'Preenchimento obrigatório' : null,
      idContaOrigem: !pedido?.idContaOrigem ? 'Preenchimento obrigatório' : null,
      idOrigemRecurso: !pedido?.idOrigemRecurso ? 'Preenchimento obrigatório' : null,
      idFornecedor: !pedido?.idFornecedor ? 'Preenchimento obrigatório' : null,
      idFormaPagamento: !pedido?.idFormaPagamento ? 'Preenchimento obrigatório' : null,
      idContaDestino: !pedido?.idContaDestino ? 'Preenchimento obrigatório' : null,
      valorBruto: !valorBrutoNormalized ? 'Preenchimento obrigatório' : null,
      descricao: !pedido?.descricao ? 'Preenchimento obrigatório' : null,
    },
  }

  if (isMoviment && isBoleto) {
    errors = {
      ...errors,
      pedidoCompra: {
        ...errors.pedidoCompra,
        dataEmissao: !pedido?.dataEmissao
          ? 'Preenchimento obrigatório'
          : dataMaiorQue(pedido?.dataEmissao, pedido?.dataPrevisaoPagamento)
          ? 'Data maior que a Data de Pagamento'
          : null,
        documentoSuporte: !pedido.documentoSuporte ? 'Preenchimento obrigatório' : null,
        numeroDocumento: !pedido.numeroDocumento ? 'Preenchimento obrigatório' : null,
        codigoBarras: !pedido.codigoBarras && isBoleto ? 'Preenchimento obrigatório' : null,
        idBancoEmissor: !pedido.idBancoEmissor && isBoleto ? 'Preenchimento obrigatório' : null,
      },
    }
  }

  if (APROVADO) {
    errors = {
      ...errors,
      pedidoCompra: {
        ...errors.pedidoCompra,
        dataEmissao: !pedido?.dataEmissao
          ? 'Preenchimento obrigatório'
          : dataMaiorQue(pedido?.dataEmissao, pedido?.dataPrevisaoPagamento)
          ? 'Data maior que a Data de Pagamento'
          : null,
        dataPrevisaoPagamento: !pedido?.dataPrevisaoPagamento
          ? 'Preenchimento obrigatório'
          : dataMenorQueHoje(pedido?.dataPrevisaoPagamento)
          ? 'Data de pagamento não deve ser retroativa'
          : null,
        documentoSuporte: !pedido.documentoSuporte ? 'Preenchimento obrigatório' : null,
        numeroDocumento: !pedido.numeroDocumento ? 'Preenchimento obrigatório' : null,
        codigoBarras: !pedido.codigoBarras && isBoleto ? 'Preenchimento obrigatório' : null,
        idBancoEmissor: !pedido.idBancoEmissor && isBoleto ? 'Preenchimento obrigatório' : null,
        escrituracao: {
          idNatureza: !pedido.escrituracao?.idNatureza ? 'Preenchimento obrigatório' : null,
        },
        valorLiquido: !pedido.valorLiquido ? 'Preenchimento obrigatório' : null,
      },
    }
  }

  return errors
}
