/* eslint eqeqeq: 0 */
/* no-mixed-operators: 0 */

import { getDateFormatedFromString, getDateNormalInvertedFormatedFromString, getDateTimeString, getDateTimeStringNormalized} from "./../../../../utils/helpers/DateTimeHelper";
import normalizeDecimals, { convertCurrencyToNumber, formatPercentToNumber } from "./../../../../utils/helpers/NumberHelper";
import { getBooleanValueFromString } from "./../../../../utils/helpers/BooleanHelper";

export function deserializeFieldsPagamentoPrecificacaoFormFilter(filters, page) {
    return {
        ...filters,
        // GESTORA
        idsAdministradorFiduciario: filters && filters?.idsAdministradorFiduciario ? filters?.idsAdministradorFiduciario.map(o => o.id) : [],
        // SEC
        idsOperacao: filters && filters.idsOperacao ? filters.idsOperacao.map(o => o.key) : [],
        idsUsuarioGestor: filters && filters.idsUsuarioGestor ? filters.idsUsuarioGestor.map(o => o.id) : [],
        idsCamaraLiquidacao: filters && filters.idsCamaraLiquidacao ? filters.idsCamaraLiquidacao.map(o => o.id) : [],
        idsBancoLiquidante: filters && filters.idsBancoLiquidante ? filters.idsBancoLiquidante.map(o => o.id) : [],
        idsCompanhiaEmissora: filters && filters.idCompanhiaEmissora ? filters.idCompanhiaEmissora.map(o => o.id) : [],
        pagingConfig: {
            pageIndex: page,
            pageSize: 10
        },
    };
}

export function deserializeFieldsPagamentoPrecificacaoFormFilterExportExcel(filters) {
    return {
        idsOperacao: filters && filters.operacao ? filters.operacao.map(o => o.key) : [],
        dataInicio: filters.dataInicio,
        dataFim: filters.dataFim,
        etapaPagamentoPrecificacao: filters.etapaPagamentoPrecificacao,
    };
}

export function serializeFieldsPagamentoFluxo(pagamentoPrecificacao) {
    let pagamentoPrecificacaoSerialized = {};
    const { base64ArquivoComprovanteAprovacao, aprovadoEm } = pagamentoPrecificacao;

    const dataAprovado = aprovadoEm && (base64ArquivoComprovanteAprovacao ? getDateTimeString(aprovadoEm) : getDateTimeStringNormalized(aprovadoEm)) || "";

    if (pagamentoPrecificacao) {
        let { precisao, amortizacaoValorAPagar, amortizacaoValorPrevisto, amortizacaoPercentual, jurosValorAPagar, jurosValorPrevisto } = pagamentoPrecificacao
        const { amortizacao, juros, taxaAmortizacao } = precisao

        amortizacaoValorAPagar = normalizeDecimals(amortizacaoValorAPagar, amortizacao.valorPrecisao, amortizacao.aplicarArredondamento, amortizacao.aplicarPrecisao)
        amortizacaoValorPrevisto = normalizeDecimals(amortizacaoValorPrevisto, amortizacao.valorPrecisao, amortizacao.aplicarArredondamento, amortizacao.aplicarPrecisao)
        jurosValorAPagar = normalizeDecimals(jurosValorAPagar, juros.valorPrecisao, juros.aplicarArredondamento, juros.aplicarPrecisao)
        jurosValorPrevisto = normalizeDecimals(jurosValorPrevisto, juros.valorPrecisao, juros.aplicarArredondamento, juros.aplicarPrecisao)
        amortizacaoPercentual = normalizeDecimals(amortizacaoPercentual, taxaAmortizacao.valorPrecisao, taxaAmortizacao.aplicarArredondamento, taxaAmortizacao.aplicarPrecisao)
        
        // prettier-ignore
        pagamentoPrecificacaoSerialized = {
            ...pagamentoPrecificacao,
            bancoLiquidanteEmails: pagamentoPrecificacao.bancoLiquidanteEmails,
            formaCalculo: pagamentoPrecificacao.formaCalculo ? pagamentoPrecificacao.formaCalculo.raw : "",
            dataEvento: pagamentoPrecificacao.dataEvento ? getDateFormatedFromString(pagamentoPrecificacao.dataEvento) : "",
            dataPagamento: pagamentoPrecificacao.dataPagamento ? getDateFormatedFromString(pagamentoPrecificacao.dataPagamento) : "",
            aprovadoEm: dataAprovado,
            jurosValorAPagar,
            jurosValorPrevisto,
            amortizacaoValorAPagar,
            amortizacaoValorPrevisto,
            amortizacaoPercentual,
        };
        
        // CÁLCULOS
        // prettier-ignore
        if (!pagamentoPrecificacao.emRevisao && (pagamentoPrecificacao.ultimaEtapa.id >= 3 && pagamentoPrecificacao.ultimaEtapa.id <= 8)) {
          let { jurosPercentual, valorFechamento, pagamentoTotalPmt, jurosValorPrevisto, jurosRemuneracao, saldoFechamento,
                amortizacaoPercentual, amex, eventoGenerico, valorFechamentoUnitario, pagamentoJuros, saldoFechamentoUnitario, pagamentoValorPrevisto,
                pagamentoTotalPmtUnitario, amortizacaoValorPrevisto, quantidadeIntegralizada, precisao } = pagamentoPrecificacao;
                
                const { amortizacao, juros, taxaAmortizacao } = precisao

                amortizacaoValorAPagar = normalizeDecimals(amortizacaoValorAPagar, amortizacao.valorPrecisao, amortizacao.aplicarArredondamento, amortizacao.aplicarPrecisao)
                amortizacaoValorPrevisto = normalizeDecimals(amortizacaoValorPrevisto, amortizacao.valorPrecisao, amortizacao.aplicarArredondamento, amortizacao.aplicarPrecisao)
                jurosValorAPagar = normalizeDecimals(jurosValorAPagar, juros.valorPrecisao, juros.aplicarArredondamento, juros.aplicarPrecisao)
                jurosValorPrevisto = normalizeDecimals(jurosValorPrevisto, juros.valorPrecisao, juros.aplicarArredondamento, juros.aplicarPrecisao)
                amortizacaoPercentual = normalizeDecimals(amortizacaoPercentual, taxaAmortizacao.valorPrecisao, taxaAmortizacao.aplicarArredondamento, taxaAmortizacao.aplicarPrecisao)

          // VERIFICA TIPO PAGAMENTO
          function checaTipo(current, max, min) {
            return pagamentoJuros === 3 ? max : (pagamentoJuros === 9 || pagamentoJuros === 12) ? min : current;
          }

          valorFechamentoUnitario = normalizeDecimals(valorFechamentoUnitario, 8)
          pagamentoTotalPmtUnitario = normalizeDecimals(pagamentoTotalPmtUnitario, 8)
          valorFechamento = normalizeDecimals(valorFechamento, 2)
          amex = normalizeDecimals(amex, 8)
          eventoGenerico = normalizeDecimals(eventoGenerico, 8)
          pagamentoTotalPmt = normalizeDecimals(pagamentoTotalPmt, 2)
  
          jurosValorAPagar = checaTipo(normalizeDecimals(((jurosPercentual / 100) * jurosRemuneracao)), jurosRemuneracao, 0);
          jurosPercentual = checaTipo(normalizeDecimals(((jurosValorAPagar / jurosRemuneracao) * 100)), 100, 0);
          pagamentoTotalPmtUnitario = normalizeDecimals((jurosValorAPagar + amortizacaoValorAPagar + amex + eventoGenerico), 8);
          valorFechamentoUnitario = normalizeDecimals((valorFechamento / quantidadeIntegralizada), 8);
          pagamentoTotalPmt = normalizeDecimals((pagamentoTotalPmtUnitario * quantidadeIntegralizada), 2);
          saldoFechamentoUnitario = normalizeDecimals((valorFechamentoUnitario - pagamentoTotalPmtUnitario), 8);
          pagamentoValorPrevisto = normalizeDecimals(((jurosValorPrevisto + amortizacaoValorPrevisto) * quantidadeIntegralizada), 2);
          
          let saldoFechamentoCalc = normalizeDecimals((valorFechamento - pagamentoTotalPmt), 2);
          saldoFechamento = saldoFechamentoCalc === 0 ? "0,00" : saldoFechamentoCalc;

          pagamentoPrecificacaoSerialized = {
            ...pagamentoPrecificacaoSerialized,
            jurosPercentual,
            jurosValorAPagar,
            valorFechamentoUnitario,
            pagamentoTotalPmtUnitario,
            pagamentoTotalPmt,
            saldoFechamentoUnitario,
            pagamentoValorPrevisto,
            saldoFechamento,
          }
        }
    } else {
        pagamentoPrecificacaoSerialized = {
            formaCalculo: { key: null, descricao: '' }
        };
    }
    return pagamentoPrecificacaoSerialized;
}

// CHECA SE É UM OBJETO
function isObj(obj) {
    return Object.keys(obj).length > 0;
}

export function deserializeFieldsEtapaFechamento(pagamento, formValues) {
    return {
        idOperacao: pagamento.idOperacao,
        idPagamentoPrecificacao: pagamento.idPagamentoPrecificacao,
        dataPagamento: getDateNormalInvertedFormatedFromString(pagamento.dataPagamento),
        dataEvento: getDateNormalInvertedFormatedFromString(pagamento.dataEvento),
        quantidadeIntegralizada: pagamento.quantidadeIntegralizada,
        pagamentoAprovacao: pagamento.pagamentoAprovacao,
        tipoEvento: pagamento.tipoEvento,
        perda: pagamento.perda,
        agenteFiduciarioIdEmpresaPrestadora: pagamento.agenteFiduciarioIdEmpresaPrestadora,
        emRevisao: pagamento.emRevisao,
        idPagamentoPrecificacaoAprovacao: pagamento.idPagamentoPrecificacaoAprovacao,
        idPagamentoPrecificacaoArquivo: pagamento.idPagamentoPrecificacaoArquivo,
        jurosRemuneracao: pagamento.jurosRemuneracao,
        correcaoMonetaria: pagamento.correcaoMonetaria,
        pagamentoCorrecaoMonetaria: pagamento.pagamentoCorrecaoMonetaria,
        // 
        saldoDisponivel: formValues.saldoDisponivel,
        pagamentoAmortizacao: formValues.pagamentoAmortizacao || false,
        agenteFiduciarioEmails: formValues.agenteFiduciarioEmails || [],
        observacao: formValues.observacao || "",
        pagamentoJuros: isObj(formValues.pagamentoJuros) && formValues.pagamentoJuros.key || formValues.pagamentoJuros || null,

        // 
        pagamentoValorPrevisto: convertCurrencyToNumber(formValues.pagamentoValorPrevisto),
        valorFechamento: convertCurrencyToNumber(formValues.valorFechamento),
        jurosValorPrevisto: convertCurrencyToNumber(formValues.jurosValorPrevisto),
        jurosValorAPagar: convertCurrencyToNumber(formValues.jurosValorAPagar),
        amortizacaoValorPrevisto: convertCurrencyToNumber(formValues.amortizacaoValorPrevisto),
        amortizacaoValorAPagar: convertCurrencyToNumber(formValues.amortizacaoValorAPagar),
        amex: convertCurrencyToNumber(formValues.amex),
        eventoGenerico: convertCurrencyToNumber(formValues.eventoGenerico),
        valorFechamentoUnitario: convertCurrencyToNumber(formValues.valorFechamentoUnitario),
        pagamentoTotalPmt: convertCurrencyToNumber(formValues.pagamentoTotalPmt),
        pagamentoTotalPmtUnitario: convertCurrencyToNumber(formValues.pagamentoTotalPmtUnitario),
        saldoFechamento: convertCurrencyToNumber(formValues.saldoFechamento),
        saldoFechamentoUnitario: convertCurrencyToNumber(formValues.saldoFechamentoUnitario),
        // 
        jurosPercentual: formatPercentToNumber(formValues.jurosPercentual),
        amortizacaoPercentual: formatPercentToNumber(formValues.amortizacaoPercentual),
    }
}

export function deserializeFieldsPagamentoValidacao(params) {
    return {
        ...params,
        emailDestinatario: params.emailDestinatario && params.emailDestinatario.length > 0 ? params.emailDestinatario.join(';') : '',
        enviarEmail: !params.enviarEmail ? false : params.enviarEmail
    };
}

export function deserializeFieldsPagamentoValidacaoConfirmacao(params) {
    return {
        ...params,
        enviarEmail: false,
        etapa: params.etapa ? params.etapa.key : null,
        confirmaValidacao: getBooleanValueFromString(params.confirmaValidacao),
        curvaPagamentoPassivo: {
            ...params.curvaPagamentoPassivo,
        },
    }
}

export function deserializeFieldsEtapaLancamento(params) {
    let confirmarLancamento = null;

    if (params.confirmarLancamento) {
        if (params.confirmarLancamento === 'Sim') {
            confirmarLancamento = true;
        } else if (params.confirmarLancamento === 'Não') {
            confirmarLancamento = false;
        }
    }

    return {
        ...params,
        idPagamentoPrecificacao: params.idPagamentoPrecificacao,
        confirmarLancamento: confirmarLancamento,
    }
}

export function deserializeFieldsEtapaPagamento(formValues) {
    let confirmarPagamento = null;

    if (formValues.confirmarPagamento) {
        if (formValues.confirmarPagamento === 'Sim') {
            confirmarPagamento = true;
        } else if (formValues.confirmarPagamento === 'Não') {
            confirmarPagamento = false;
        }
    }

    return {
        ...formValues,
        idPagamentoPrecificacao: formValues.idPagamentoPrecificacao,
        confirmarPagamento: confirmarPagamento,
        curvaPagamentoPassivo: {
            ...formValues.curvaPagamentoPassivo,
        },
    }
}

export function deserializeFieldsPagamentoAgendamento(params) {
    return {
        ...params,
        emailDestinatario: params.emailDestinatario && params.emailDestinatario.length > 0 ? params.emailDestinatario.join(';') : '',
        enviarEmail: !params.enviarEmail ? false : params.enviarEmail
    };
}